// Sidebar.js
import "./sidebar.scss";
import useScreenWidth from "../../../hooks/useScreenwidth";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Setting from "../../icons/setting";
import Dashboard from "../../icons/dashboard";
import CompleteLogo from "../../../assets/images/brand/white_bg.png";
import Logo from "../../../assets/images/brand/logo.png";
import Funding from "../../icons/Funding";
import Payments from "../../icons/Payments";
import Notes from "../../icons/Notes";
import Tools from "../../icons/Tools";
import Counterparty from "../../icons/Counterparty";
import LeftArrow from "../../icons/LeftArrow";
import CustomNavLink from "./CustomNavLink"; // Ensure the import
import CircleAvatar from "../../atoms/CircleAvatar";
import Signer from "../../icons/Signer";
import Broker from "../../icons/Broker";
import Investor from "../../icons/Investor";
import Insurance from "../../icons/Insurance";
import Bottomarrow from "../../icons/Bottomarrow";
import LogoutModal from "../../atoms/modals/LogoutModal";

import { Menu, MenuItem } from "@mui/material";
import UpwardArrow from "../../icons/UpwardArrow";
import { decodeCookieValue } from "../../../helpers/cookieHelper";
import { capitalizeLetter } from "../../../helpers/capatalizeLetters";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import Monitoring from "../../icons/monitoring";
import Funds from "../../icons/Funds";
import ReportsIcon from "../../icons/reports";

const Sidebar = ({ isActive, setIsActive, sidebarRef }) => {
  const screenWidth = useScreenWidth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies("un");
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const links = [
    ...(accessData && accessData?.["Dashboard"]
      ? [
          {
            to: "/dashboard",
            label: "Dashboard",
            icon: <Dashboard />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData && accessData?.["Funding"]
      ? [
          {
            to: "/dashboard/funding",
            label: "Funding",
            icon: <Funding />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    // ...(accessData && accessData?.["Funding"]
    //   ? [
    //       {
    //         to: "/dashboard/funds",
    //         label: "Funds",
    //         icon: <Funds />,
    //         alternatePath: "/dashboard/setting",
    //       },
    //     ]
    //   : []),
    ...(accessData && accessData?.["Payment"]
      ? [
          {
            to: "/dashboard/payments",
            label: "Payments",
            icon: <Payments />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData && accessData?.["Notes"]
      ? [
          {
            to: "/dashboard/notes",
            label: "Notes",
            icon: <Notes />,
          },
        ]
      : []),
    ...(accessData && accessData?.["Tools"]
      ? [
          {
            to: "/dashboard/tools",
            label: "Tools",
            icon: <Tools />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData && accessData?.["Counterparties"]
      ? [
          {
            to: "/dashboard/counterparty",
            label: "Counterparties",
            icon: <Counterparty />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData && accessData?.["Monitoring"]
      ? [
          {
            // to: "/dashboard/profile-monitoring",
            label: "Reports",
            icon: <ReportsIcon />,
            alternatePath: "/dashboard/setting",
            subLinks: [
              {
                to: "/dashboard/reports/portfolio",
                label: "Portfolio",
              },
              {
                to: "/dashboard/reports/accural_day",
                label: "Accrual(Day)",
              },
              {
                to: "/dashboard/reports/accural_daily",
                label: "Accrual(Daily)",
              },
              {
                to: "/dashboard/reports/acuural_exp",
                label: "Accrual(Exp)",
              },
              {
                to: "/dashboard/reports/accural_icy",
                label: "Accrual(Icy)",
              },
              {
                to: "/dashboard/reports/sector_geography",
                label: "Sector/Geography",
              },
              {
                to: "/dashboard/reports/due",
                label: "Due",
              },
              {
                to: "/dashboard/reports/insurance",
                label: "Insurance",
              },
              {
                to: "/dashboard/reports/brokers",
                label: "Brokers",
              },
              {
                to: "/dashboard/reports/rates",
                label: "Rates",
              },
              {
                to: "/dashboard/reports/reporting",
                label: "Reporting",
              },
              {
                to: "/dashboard/reports/covenants",
                label: "Covenants",
              },
              {
                to: "/dashboard/reports/ir",
                label: "IR",
              },
            ],
          },
        ]
      : []),
    ...(accessData && accessData?.["Signers"]
      ? [
          {
            to: "/dashboard/signer",
            label: "Signers",
            icon: <Signer />,
          },
        ]
      : []),
    ...(accessData && accessData?.["Brokers"]
      ? [
          {
            to: "/dashboard/broker",
            label: "Brokers",
            icon: <Broker />,
          },
        ]
      : []),
    ...(accessData && accessData?.["Investors"]
      ? [
          {
            to: "/dashboard/investor",
            label: "Investment",
            icon: <Investor />,
          },
        ]
      : []),
    ...(accessData && accessData?.["Insurance"]
      ? [
          {
            to: "/dashboard/insurance",
            label: "Insurance",
            icon: <Insurance />,
          },
        ]
      : []),
    // ...(accessData && accessData?.["Monitoring"]
    //   ? [
    {
      // to: "/dashboard/profile-monitoring",
      label: "Monitoring",
      icon: <Monitoring />,
      alternatePath: "/dashboard/setting",
      subLinks: [
        {
          to: "/dashboard/profile-monitoring",
          label: "Dashboard",
        },
        {
          to: "/dashboard/monitoring/links/analysis",
          label: "Analysis",
        },
        {
          to: "/dashboard/monitoring/valuation",
          label: "Valuation",
        },
      ],
    },
    //   ]
    // : []),
    ...(accessData &&
    (accessData?.["Rates"] ||
      accessData?.["Pricing"] ||
      accessData?.["Covenants"] ||
      accessData?.["Reporting Requirnments"] ||
      accessData?.["Role Group Managenment"] ||
      accessData?.["Role Management"] ||
      accessData?.["User Management"])
      ? [
          {
            label: "Configurations",
            icon: <Setting />,
            subLinks: [
              ...(accessData && accessData?.["Rates"]
                ? [
                    {
                      to: "/dashboard/setting/rate-management",
                      label: "Rates",
                    },
                  ]
                : []),
              ...(accessData && accessData?.["Pricing"]
                ? [
                    {
                      to: "/dashboard/setting/price",
                      label: "Pricing",
                    },
                  ]
                : []),
              ...(accessData && accessData?.["Covenants"]
                ? [
                    {
                      to: "/dashboard/setting/covenant",
                      label: "Covenants",
                    },
                  ]
                : []),
              ...(accessData && accessData?.["Reporting Requirements"]
                ? [
                    {
                      to: "/dashboard/setting/reporting-requirements",
                      label: "Reporting Requirements",
                    },
                  ]
                : []),
              ...((accessData && accessData?.["User Management"]) ||
              accessData?.["Role Management"] ||
              accessData?.["Role Group Management"]
                ? [
                    {
                      to: "/dashboard/setting/user-management",
                      label: "User Management",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];

  const memoizedSetIsActive = useCallback((newIsActive) => {
    setIsActive(newIsActive);
  }, []);

  useEffect(() => {
    memoizedSetIsActive(screenWidth >= 1233);
  }, [screenWidth, memoizedSetIsActive]);

  const handleSideBar = () => {
    // if (screenWidth < 1233) setIsActive(false);
  };

  return (
    <div className={`sidebar ${!isActive ? "closed" : ""}`} ref={sidebarRef}>
      <div className="header">
        {isActive ? (
          <img
            src={CompleteLogo}
            alt="Logo"
            className="open-header-img"
            onClick={() => navigate("/dashboard")}
          />
        ) : (
          <img
            src={Logo}
            alt="Logo"
            className="closed-header-img"
            onClick={() => navigate("/dashboard")}
          />
        )}
        <div
          className={`drawer-div ${!isActive ? "closed" : ""}`}
          onClick={() => setIsActive((prev) => !prev)}
        >
          <LeftArrow />
        </div>
      </div>
      <ul className="ul">
        {links.map((link, index) => (
          <CustomNavLink
            key={index}
            to={link.to}
            label={link.label}
            icon={link.icon}
            handleSideBar={handleSideBar}
            isSideBarActive={isActive}
            setIsSideBarActive={setIsActive}
          >
            {link.subLinks?.map((subLink, subIndex) => (
              <CustomNavLink
                key={subIndex}
                to={subLink.to}
                label={subLink.label}
                handleSideBar={handleSideBar}
                isSideBarActive={isActive}
              />
            ))}
          </CustomNavLink>
        ))}
      </ul>
      <div className="profile" onClick={handleProfileMenu}>
        <div className="d-flex gap-2">
          <CircleAvatar
            color="#DEE3E7"
            iconColor="black"
            name={capitalizeLetter(decodeCookieValue(cookies.un))}
          />
          {isActive && (
            <span className="profile-span">
              {capitalizeLetter(decodeCookieValue(cookies.un))}
            </span>
          )}
        </div>

        {isActive && (anchorEl ? <UpwardArrow /> : <Bottomarrow />)}
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            // width: '200px',
            borderRadius: "6px",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            marginBottom: "40px",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "bottom",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "bottom",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/dashboard/edit-profile");
            setAnchorEl(null);
          }}
          style={{ fontWeight: 500 }}
        >
          <span>Edit Profile</span>
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate("/dashboard/change-password");
            setAnchorEl(null);
          }}
          style={{ fontWeight: 500 }}
        >
          <span>Change Password</span>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsModalOpen(!isModalOpen);
            setAnchorEl(null);
          }}
          style={{ fontWeight: 500 }}
        >
          <span style={{ color: "#BE0F00" }}>Logout</span>
        </MenuItem>
      </Menu>

      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Sidebar;
