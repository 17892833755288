import moment from "moment";
import style from "../index.module.scss";
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../../atoms/CustomButton";
import { useEffect, useState } from "react";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import {
  calculateFinalBaseRate,
  getApplicableBaseRates,
} from "../../../../../../helpers/finalDateCalculate";

const InterestDetails = ({ fundedDataById, pricingData }) => {
  const [cookies] = useCookies(["t"]);
  const [interestDate, setInterestData] = useState({
    startDate: null,
    endDate: null,
  });
  const [interestRateFloating, setInterestRateFloating] = useState();
  const [interestRateFlat, setInterestFlat] = useState();
  const [dailyInterest, setDailyInterest] = useState([]);
  const [runQuery, setRunQuery] = useState(false);

  const dueDate = moment(fundedDataById?.createdAt).add(
    parseInt(fundedDataById?.payment_terms),
    "days",
  );

  const getInterestRate = async (rateID) => {
    try {
      const res = await getData({
        endpoint: "rates/getRateById",
        token: cookies.t,
        params: { id: rateID },
      });
      setInterestRateFloating(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (pricingData?.rate_type === "Floating") {
      getInterestRate(pricingData?.base_rate);
    } else if (pricingData?.rate_type === "Flat") {
      setInterestFlat(pricingData?.top_rate);
    }
  }, [pricingData]);

  const convertToDDMMYYYY = (isoDate) => {
    const date = new Date(isoDate); // Convert ISO date string to Date object
    const day = String(date.getDate()).padStart(2, "0"); // Get the day and pad it to 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get the month (0-based, so +1) and pad it
    const year = date.getFullYear(); // Get the year

    return `${day}-${month}-${year}`; // Return the formatted date as DD-MM-YYYY
  };

  useEffect(() => {
    if (interestRateFloating?.rates && runQuery) {
      if (
        fundedDataById?.transaction_type === "Revolver" ||
        fundedDataById?.transaction_type === "Note"
      ) {
        const tempTotalInterest = getApplicableBaseRates(
          interestRateFloating?.rates,
          fundedDataById?.funded_date,
          dueDate,
        );
        const finalTotalInterest = calculateFinalBaseRate(tempTotalInterest);
        const tempDailyInterest = calculateDailyCompoundInterest(
          parseFloat(fundedDataById?.cost_value),
          parseFloat(finalTotalInterest) + parseFloat(pricingData?.top_rate),
          // interestDate.startDate,
          fundedDataById?.funded_date,
          interestDate.endDate,
        );
        const filteredData = tempDailyInterest.filter((item) => {
          return (
            item.date >= convertToDDMMYYYY(interestDate.startDate) &&
            item.date <= convertToDDMMYYYY(interestDate.endDate)
          );
        });
        setDailyInterest(filteredData);
      } else {
        if (
          fundedDataById?.transaction_type === "Revolver" ||
          fundedDataById?.transaction_type === "Note"
        ) {
          const tempTotalInterest = getApplicableBaseRates(
            interestRateFloating?.rates,
            fundedDataById?.funded_date,
            dueDate,
          );
          const finalTotalInterest = calculateFinalBaseRate(tempTotalInterest);
          const tempDailyInterest = calculateDailyCompoundInterest(
            parseFloat(fundedDataById?.cost_value),
            parseFloat(finalTotalInterest) + parseFloat(pricingData?.top_rate),
            // interestDate.startDate,
            fundedDataById?.funded_date,
            interestDate.endDate,
          );
          const filteredData = tempDailyInterest.filter((item) => {
            return (
              item.date >= convertToDDMMYYYY(interestDate.startDate) &&
              item.date <= convertToDDMMYYYY(interestDate.endDate)
            );
          });
          setDailyInterest(filteredData);
        } else {
          const tempTotalInterest = getApplicableBaseRates(
            interestRateFloating?.rates,
            fundedDataById?.funded_date,
            dueDate,
          );
          const finalTotalInterest = calculateFinalBaseRate(tempTotalInterest);
          const tempDailyInterest = calculateDailyCompoundInterest(
            parseFloat(fundedDataById?.cost_value),
            parseFloat(finalTotalInterest) + parseFloat(pricingData?.top_rate),
            // interestDate.startDate,
            fundedDataById?.funded_date,
            interestDate.endDate,
          );
          const filteredData = tempDailyInterest.filter((item) => {
            return (
              item.date >= convertToDDMMYYYY(interestDate.startDate) &&
              item.date <= convertToDDMMYYYY(interestDate.endDate)
            );
          });
          setDailyInterest(filteredData);
        }
      }
    } else if (interestRateFlat && runQuery) {
      const tempDailyInterest = calculateDailyCompoundInterest(
        parseFloat(fundedDataById?.cost_value),
        pricingData?.top_rate,
        fundedDataById?.createdAt,
        interestDate.endDate,
      );
      const filteredData = tempDailyInterest.filter((item) => {
        return (
          item.date >= convertToDDMMYYYY(interestDate.startDate) &&
          item.date <= convertToDDMMYYYY(interestDate.endDate)
        );
      });
      setDailyInterest(filteredData);
    }
  }, [interestRateFloating, runQuery, interestRateFlat]);

  const calculateDailyCompoundInterest = (
    principal,
    annualRate,
    startDate,
    endDate,
  ) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dailyInterestRate = annualRate / 36000;

    let currentDate = new Date(start);
    const dailyInterest = principal * dailyInterestRate;
    let accumulatedInterest = dailyInterest;
    const results = [];

    while (currentDate <= end) {
      const formattedDate = currentDate.toLocaleDateString("en-GB");
      const result = {};
      result.date = formattedDate;
      result[formattedDate] = accumulatedInterest.toFixed(2);
      result["Amount"] =
        parseFloat(principal) + parseFloat(result[formattedDate]);
      results.push(result);

      accumulatedInterest += dailyInterest;
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return results;
  };

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Asset Information</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Transaction Type</td>
                <td className={style.value}>
                  {fundedDataById?.transaction_type}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Term</td>
                <td className={style.value}>{fundedDataById?.payment_terms}</td>
              </tr>
              <tr>
                <td className={style.label}>Cost</td>
                <td className={style.value}>{fundedDataById?.cost_value}</td>
              </tr>
              <tr>
                <td className={style.label}>Face Value</td>
                <td className={style.value}>
                  {parseFloat(fundedDataById?.cost_value) +
                    parseFloat(fundedDataById?.face_value)}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Currency</td>
                <td className={style.value}>{fundedDataById?.currency}</td>
              </tr>
              {fundedDataById?.transaction_type === "Supplier" && (
                <tr>
                  <td className={style.label}>Invoice Date</td>
                  <td className={style.value}>
                    {" "}
                    {moment(fundedDataById?.createdAt).format("YYYY-MM-DD")}
                  </td>
                </tr>
              )}
              {fundedDataById?.transaction_type !== "Supplier" && (
                <tr>
                  <td className={style.label}>Invoice Date</td>
                  <td className={style.value}>
                    {" "}
                    {moment(fundedDataById?.invoice_date).format("YYYY-MM-DD")}
                  </td>
                </tr>
              )}
              {(fundedDataById?.transaction_type === "Supplier" ||
                fundedDataById?.transaction_type === "Supplier Credit-1") && (
                <tr>
                  <td className={style.label}>Due Date</td>
                  <td className={style.value}>
                    {dueDate.format("YYYY-MM-DD")}
                  </td>
                </tr>
              )}
              {fundedDataById?.due_date && (
                <tr>
                  <td className={style.label}>Due Date</td>
                  <td className={style.value}>
                    {moment(fundedDataById?.due_date).format("YYYY-MM-DD")}
                  </td>
                </tr>
              )}
              <tr>
                <td className={style.label}>Funding Date</td>
                <td className={style.value}>
                  {moment(fundedDataById?.funded_date).format("YYYY-MM-DD")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Pricing Information</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Name</td>
                <td className={style.value}>{pricingData?.name}</td>
              </tr>
              <tr>
                <td className={style.label}>Description</td>
                <td className={style.value}>{pricingData?.description}</td>
              </tr>
              <tr>
                <td className={style.label}>Top Rate</td>
                <td className={style.value}>{pricingData?.top_rate}</td>
              </tr>
              <tr>
                <td className={style.label}>Days</td>
                <td className={style.value}>{pricingData?.days}</td>
              </tr>
              <tr>
                <td className={style.label}>Advance Rate</td>
                <td className={style.value}>{pricingData?.advance_rate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.fundedViewRightWrap}>
        <Heading className="HeadingSubStyle">Accrued Interest Table</Heading>
        <div className={style.DateRangeWrap}>
          <div style={{ position: "relative", width: "40%" }}>
            <DateSelector
              placeholder="Start Date"
              onChange={(option) => {
                setInterestData((prev) => ({
                  ...prev,
                  startDate: option,
                }));
                setRunQuery(false);
              }}
              minDateData={
                fundedDataById?.transaction_type === "Supplier" ||
                fundedDataById?.transaction_type === "Supplier Credit-1" ||
                fundedDataById?.transaction_type === "Client" ||
                fundedDataById?.transaction_type === "Revolver" ||
                fundedDataById?.transaction_type === "Note"
                  ? fundedDataById?.funded_date
                  : fundedDataById?.invoice_date
              }
              selectedDate={interestDate.startDate}
            />
          </div>
          <div style={{ position: "relative", width: "40%" }}>
            <DateSelector
              placeholder="End Date"
              onChange={(option) => {
                setInterestData((prev) => ({
                  ...prev,
                  endDate: option,
                }));
                setRunQuery(false);
              }}
              minDateData={interestDate.startDate}
              selectedDate={interestDate.endDate}
            />
          </div>
        </div>
        <CustomButton
          text="Run"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setRunQuery(true);
          }}
        />
        {runQuery && (
          <div className={` mt-4 ${style.InterestDetailsWrap}`}>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray">
                  <th colSpan="3" className="border p-2 text-left">
                    Principle
                  </th>
                  <th colSpan="3" className="border p-2 text-left">
                    Interest
                  </th>
                </tr>
                <tr className="bg-gray">
                  <th className="border p-2 text-left">Date</th>
                  <th className="border p-2 text-left">Principle Remaining</th>
                  <th className="border p-2 text-left">Principle Paid</th>
                  <th className="border p-2 text-left">Interest Paid</th>
                  <th className="border p-2 text-left">Interest Accrued</th>
                  <th className="border p-2 text-left">Amount to Close</th>
                </tr>
              </thead>
              <tbody>
                {dailyInterest?.map((row, index) => {
                  return (
                    <tr className="" key={index}>
                      <td className="border p-2">{row.date}</td>
                      <td className="border p-2">
                        {fundedDataById?.cost_value}
                      </td>
                      <td className="border p-2">{0.0}</td>
                      <td className="border p-2">{0.0}</td>
                      <td className="border p-2">{row[row.date]}</td>
                      <td className="border p-2">
                        {fundedDataById?.transaction_type === "Revolver" ||
                        fundedDataById?.transaction_type === "Note" ? (
                          row["Amount"]
                        ) : (
                          <>
                            {parseFloat(fundedDataById?.face_value) +
                              parseFloat(fundedDataById?.cost_value)}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
export default InterestDetails;
