import React, { useRef, useEffect } from "react";
import CloseButton from "./CloseButton";
import "./styles.scss";

const Modal = ({
  id,
  title,
  isActive,
  onClose,
  children,
  width = "450px",
  bodyStyles,
  extrastyle,
  maxHeight,
  overflow,
}) => {
  const modalContentRef = useRef(null);

  useEffect(() => {
    const handleOverflowChange = () => {
      const content = modalContentRef.current;

      if (content.scrollHeight > content.clientHeight) {
        // Content overflows, add the 'scrollable' class
        content.classList.add("scrollable");
      } else {
        // Content does not overflow, remove the 'scrollable' class
        content.classList.remove("scrollable");
      }
    };

    // Listen for changes in overflow
    const content = modalContentRef.current;
    content.addEventListener("scroll", handleOverflowChange);

    // Check overflow initially
    handleOverflowChange();

    // Cleanup listener
    return () => {
      content.removeEventListener("scroll", handleOverflowChange);
    };
  }, []);

  const styles = {
    ...extrastyle,
    width: width,
    maxHeight: maxHeight,
    overflow: overflow,
  };
  // const styleCase = {
  //   fontSize: '20px',
  //   fontWeight: 'bold',
  // };
  const bodyStyle = {
    ...bodyStyles,
    ...extrastyle,
    overflow: overflow,
  };
  console.log("overflow", overflow);
  return (
    <div id={id} className={`modal ${isActive ? "active" : ""}`}>
      <div
        className="modal-content"
        style={{ ...styles }}
        ref={modalContentRef}
      >
        <div className="modal-header">
          <p className="modal-heading mb-0">{title}</p>
          <CloseButton onClose={onClose} />
        </div>
        <div className="modal-body" style={{ ...bodyStyle }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
