import React, { useState, memo, useEffect, useCallback } from "react";
import CustomButton from "../../../../atoms/CustomButton";
import { Heading } from "../../../../atoms/Heading";
import { useNavigate } from "react-router-dom";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../atoms/NoContentCard";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import Reporting from "../../../../icons/Reporting";
import Delete from "../../../../icons/Delete";
import DeleteModal from "../../../../atoms/modals/DeleteModal";
import { deleteData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { convenantFrequency } from "../../../../../helpers/staticData";
import moment from "moment";
import { useSelector } from "react-redux";

const Covenant = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["Reporting Requirnments"];
  const [searchData, setSearchData] = useState("");
  const [covenantData, setCovenantData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    unique_row_id : '',
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    name: "",
  });
  const [filters, setFilters] = useState({});

  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "reporting-requirements/getReqClientList",
        token: cookies.t,
      });
      if (res) {
        setClientData(res?.data);
      }
    })();
  }, []);
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "reporting-requirements/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId , reporting_requirement_id : modalStateDelete?.unique_row_id},
      });
      if (res) {
        toast.success("Covenant Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        getCovenantData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    // {
    //   icon: <Reporting />,
    //   label: "Reporting Requirement",
    //   onClick: () => {
    //     navigate(`reporting-requirement/${selectedRow?._id}`);
    //   },
    // },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
          unique_row_id : selectedRow.unique_row_id
        });
        setAnchorEl(null);
      },
    },
  ];

  const getCovenantData = async () => {
    try {
      const res = await getData({
        endpoint: "/reporting-requirements/getAll",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          frequency: filters?.["Frequency"],
          clients: filters?.["Client"],
        },
      });
      setCovenantData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getCovenantData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 0.8,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.client_name}</div>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.8,
      renderCell: (params) => {
        return <div className="valueStyle" title={params.row.name}>{params?.row?.name}</div>;
      },
    },
    {
      field: "long_description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="valueStyle" title={params?.row?.long_description}>{params?.row?.long_description}</div>
        );
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 0.8,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.frequency}</div>;
      },
    },
    {
      field: "shift",
      headerName: "Shift",
      flex: 0.5,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.monthly_shift}</div>;
      },
    },
    {
      field: "reporting_days",
      headerName: "Reporting Days",
      flex: 0.5,
    },
    {
      field: "next_reporting_date",
      headerName: "Next Report as of date ",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {moment(params.row.next_reporting_date).format("Do MMM, YYYY")}
          </span>
        );
      },
    },
    {
      field: "next_due_date",
      headerName: "Next due date",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>{moment(params.row.next_due_date).format("Do MMM, YYYY")}</span>
        );
      },
    },
    {
      field: "other_notes",
      headerName: "Other Note",
      flex: 1,
      renderCell: (params) => {
        return <div className="valueStyle" title={params?.row?.other_notes}>{params?.row?.other_notes}</div>;
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 0.8,
    //   renderCell: (params) => {
    //     return ;
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        {!!(searchData || Object.keys(filters).length > 0) ? (
          <NoContentCard
            title="No Reporting Requirement Found!"
            type="Pricing"
            classes="d-flex align-items-center"
          />
        ) : (
          <NoContentCard
            title="No Requirement added yet!"
            tag="Add Reporting Requirement"
            type="Pricing"
            classes="d-flex align-items-center"
            {...(roleData && roleData?.["add/edit"]
              ? {
                  handleClick: () => {
                    navigate("add");
                  },
                  subtitle:
                    "No Requirement added yet click on a Add Reporting Requirement button to add reporting requirement.",
                }
              : {})}
          />
        )}
      </>
    );
  });

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    Frequency: convenantFrequency,
    Client: clientData,
  };

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Reporting Requirement</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />
          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Add Reporting Requirement"
              type="btn-primary"
              handleClick={() => {
                navigate("add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={covenantData?.[0]?.data || []}
        getRowId={(row) => row?.unique_row_id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {covenantData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={covenantData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modalStateDelete.isModalOpen && (
        <DeleteModal
          isActive={modalStateDelete.isModalOpen}
          onClose={handleCloseDelete}
          onClick={handleDelete}
          title="Reporting Requirement"
          name={modalStateDelete.name}
        />
      )}
    </>
  );
};

export default Covenant;
