import React from "react";

const FinancialAnalysis = () => {
  return (
    <div style={styles.container}>
      <h3>Financial Analysis</h3>

      <div style={styles.section}>
        <strong>New Analysis:</strong>
        <a href="/dashboard/monitoring/analysis" style={styles.link}>
          Financial Analysis File Upload
        </a>
      </div>

      <div style={styles.section}>
        <strong>Reporting File Review:</strong>
        <a href="/dashboard/monitoring/analysis" style={styles.link}>
          Reporting and Financial Analysis Review
        </a>
      </div>

      <div style={styles.section}>
        <strong>Analysis File Review:</strong>
        <a href="/dashboard/monitoring/analysis/review" style={styles.link}>
          Financial Analysis Review
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  section: {
    marginBottom: "20px",
    padding: "10px",
    paddingLeft: "0px",
    borderRadius: "4px",
  },
  link: {
    display: "block",
    marginTop: "5px",
    color: "#0066cc",
    textDecoration: "none",
  },
};

export default FinancialAnalysis;
