import React, { useState, useMemo } from "react";
import { Heading } from "../../../../atoms/Heading";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import TabStrip from "../../../../atoms/tabstrip";
import MoreTabstrip from '../../../../atoms/tabstrip/MoreTabstrip'
import { Box } from "@mui/material";
import BasicInfo from "./BasicInfo";
import Dues from "./Dues";
import ExposureGraph from "./ExposureGraph";
import ExposureBasedOnGraphs from "./ExposureBasedOnGraphs";

const View = () => {
  const [activeTab, setActiveTab] = useState("Basic Info");

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Basic Info",
        view: <BasicInfo/>,
      },
      {
        id: 2,
        label: "Due",
        view: <Dues/>,
      },
      {
        id: 3,
        label: "Liquidity: Cumulative",
        view: <></>,
      },
      {
        id: 4,
        label: "Liquidity: Daily",
        view: <></>,
      },
      {
        id: 5,
        label: "Exposure: Client",
        view: <ExposureGraph title='Exposure: Client' type='Client'/>,
      },
      {
        id: 5,
        label: "Exposure: Obligor",
        view: <ExposureGraph title='Exposure: Obligor' type='Obligor'/>,
      },
      {
        id: 6,
        label: "Exposure: Supplier",
        view: <ExposureGraph title='Exporsure: Supplier' type='Supplier'/>,
      },
      {
        id: 7,
        label: "Exposure: Supplier Credit-1",
        view: <ExposureGraph title='Exposure: Supplier Credit-1' type='Supplier Credit-1'/>,
      },
      {
        id: 8,
        label: "Exposure: Revolver",
        view: <ExposureGraph title='Exposure: Revolver' type='Revolver'/>,
      },
      {
        id: 9,
        label: "Exposure: Obligor Geography",
        view: <ExposureBasedOnGraphs title={'Exposure: Obligor Geography'} basedOn={'geography'}/>,
      },
      {
        id: 10,
        label: "Exposure: Obligor Sector",
        view: <ExposureBasedOnGraphs title={'Exposure: Obligor Sector'} basedOn={'gics_sector'}/>,
      },
      {
        id: 9,
        label: "Exposure: Obligor Industry",
        view: <ExposureBasedOnGraphs title={'Exposure: Obligor Industry"'} basedOn={'gics_industry'}/>,
      },
    ],
    [],
  );

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <Breadcrumb labels={["Investors", "View Details"]} excludeLastPath />
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Details</Heading>
        </div>
      </div>

      <MoreTabstrip 
      activeTab={activeTab}
      onTabChange={onTabChange}
      data={data}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default View;
