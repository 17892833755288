import React, { useEffect, useMemo, useState } from "react";
import Tabstrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/material";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { Heading } from "../../../../atoms/Heading";
import Basic from "../add/Basic";
import { useLocation, useParams } from "react-router-dom";
import { isDraft } from "@reduxjs/toolkit";
import ViewCounterparty from ".";
import CounterPartyDue from "./Dues";

const ViewCounterTabs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Related Parties Info");
  const { id, isDraft } = useParams();

  useEffect(() => {
    if (location?.state) {
      setActiveTab(location?.state?.edit);
    }
  }, [location?.state]);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Related Parties Info",
        view: <ViewCounterparty type="Related Parties Info" />,
      },
      {
        id: 2,
        label: "Internal Info",
        view: <ViewCounterparty type="Internal Info" />,
      },
      {
        id: 3,
        label: "Due",
        view: (
          <>
            <CounterPartyDue />
          </>
        ),
      },
      {
        id: 4,
        label: "Liquidity: Cumulative (WIP)",
        view: <></>,
      },
      {
        id: 5,
        label: "Exposure: Obligor",
        view: <></>,
      },
      {
        id: 6,
        label: "Exposure: Supplier",
        view: <></>,
      },
      {
        id: 7,
        label: "Monitoring: Send",
        view: <></>,
      },
      {
        id: 8,
        label: "Monitoring: Add",
        view: <></>,
      },
      {
        id: 9,
        label: "Monitoring: Review",
        view: <></>,
      },
      {
        id: 10,
        label: "Monitoring: Covenants",
        view: <></>,
      },
    ],
    [],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const handleAllowId = () => {
    if (location?.state?.edit === "Client") {
      return 1;
    } else if (location?.state?.edit === "Supplier") {
      return 2;
    } else if (localStorage?.state?.edit === "Obligor") {
      return 3;
    } else {
      return 4;
    }
  };

  return (
    <>
      <Breadcrumb
        labels={["Counterparties", "View Counterparties"]}
        excludeLastPath
      />
      <Heading className="addHeaderStyle">View Counterparty </Heading>

      <Tabstrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={data}
        allowedTabId={location?.state?.edit ? handleAllowId : ""}
        areTabsDisabled={location?.state?.edit ? true : false}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default ViewCounterTabs;
