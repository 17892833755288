import moment from "moment";
import style from "../index.module.scss";
import FileViewer from "react-file-viewer";
import { useEffect, useState } from "react";
import { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import * as XLSX from "xlsx";
import CustomButton from "../../../../../atoms/CustomButton";
import { getPandaApi } from "../../../../../../services/pandaDocs";

const BasicInfo = ({
  fundedDataById,
  pandaDocStatus,
  handlePandaDocsStatus,
}) => {
  const [fundingData, setFundingData] = useState();
  const [excelData, setExcelData] = useState(null);
  const [excelTypeData, setExcelTypeData] = useState({
    fileType: "",
    file: "",
  });
  const [currentIndex, setCurrentIndex] = useState({
    docIndex: 0,
    fileType: "",
    imagesType: "", // Add imagesType to the state
  });

  const [documentsGroupItem, setDocumentsGroupItem] = useState({});

  useEffect(() => {
    if (fundedDataById?.documentsInvoice?.docInvoiceId) {
      handlePandaDocsStatus();
    }
  }, [fundedDataById?.documentsInvoice?.docInvoiceId]);
  useEffect(() => {
    if (fundedDataById) {
      setFundingData(fundedDataById);
    }
    if (fundedDataById?.documents) {
      const groupedDocuments = fundedDataById?.documents.reduce((acc, doc) => {
        if (!acc[doc?.imagesType]) {
          acc[doc?.imagesType] = [];
        }
        acc[doc?.imagesType].push(doc);
        return acc;
      }, {});
      setDocumentsGroupItem(groupedDocuments);
      const firstImagesType = Object.keys(groupedDocuments)[0];
      if (firstImagesType) {
        setCurrentIndex({
          docIndex: 0, // Set to the first document
          fileType: groupedDocuments[firstImagesType][0]?.imagesType,
          imagesType: firstImagesType, // Set to the first imagesType
        });
      }
    }
  }, [fundedDataById]);

  const handleIndexFile = (index, doc, imagesType) => {
    setCurrentIndex(() => ({
      docIndex: index,
      fileType: doc?.imagesType,
      imagesType: imagesType, // Set the imagesType when a file is clicked
    }));

    const type = getFileType(doc?.file);
    if (type.toLowerCase() === "xls" || type.toLowerCase() === "xlsx") {
      setExcelTypeData(() => ({
        fileType: type,
        file: doc?.file,
      }));
    }
  };

  const xlsxFileDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      setExcelData(sheetData);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (excelTypeData?.file) {
      xlsxFileDownload(excelTypeData.file);
    }
  }, [excelTypeData]);

  useEffect(() => {
    if (fundingData?.documents?.[0]?.file) {
      const fileTypeAtIndex0 = getFileType(fundingData.documents[0]?.file);
      if (fileTypeAtIndex0 === "xls" || fileTypeAtIndex0 === "xlsx") {
        setExcelTypeData(() => ({
          fileType: fileTypeAtIndex0,
          file: fundingData.documents[0]?.file,
        }));
      }
    }
  }, [fundingData?.documents, currentIndex]); // Run this effect when fundingData changes

  const getFileType = (url) => {
    const extension = url?.split(".")?.pop();
    return extension?.toLowerCase();
  };

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Asset Information</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={style.label}>Transaction Type</td>
              <td className={style.value}>
                {fundedDataById?.transaction_type}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Client Invoice ID/Ref</td>
              <td className={style.value}>
                {fundedDataById?.invoice_number || fundedDataById?.referance}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Client Name</td>
              <td className={style.value}>{fundedDataById?.client_name}</td>
            </tr>
            {fundedDataById?.obligor_name && (
              <tr>
                <td className={style.label}>Obligor Name</td>
                <td className={style.value}>{fundedDataById?.obligor_name}</td>
              </tr>
            )}
            {fundedDataById?.supplier_name && (
              <tr>
                <td className={style.label}>Supplier Name</td>
                <td className={style.value}>{fundedDataById?.supplier_name}</td>
              </tr>
            )}
            <tr>
              <td className={style.label}>Term</td>
              <td className={style.value}>{fundedDataById?.payment_terms}</td>
            </tr>
            {fundedDataById?.req_advance_value && (
              <tr>
                <td className={style.label}>Requested Advance Value</td>
                <td className={style.value}>
                  {fundedDataById?.req_advance_value}
                </td>
              </tr>
            )}
            <tr>
              <td className={style.label}>Cost</td>
              <td className={style.value}>{fundedDataById?.cost_value}</td>
            </tr>
            <tr>
              <td className={style.label}>Face Value</td>
              <td className={style.value}>
                {parseFloat(fundedDataById?.face_value) +
                  parseFloat(fundedDataById?.cost_value)}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Currency</td>
              <td className={style.value}>{fundedDataById?.currency}</td>
            </tr>
            <tr>
              <td className={style.label}>Invoice Date</td>
              <td className={style.value}>
                {" "}
                {moment(fundedDataById?.invoice_date).format("YYYY-MM-DD")}
              </td>
            </tr>
            {fundedDataById?.due_date && (
              <tr>
                <td className={style.label}>Due Date</td>
                <td className={style.value}>
                  {moment(fundedDataById?.due_date).format("YYYY-MM-DD")}
                </td>
              </tr>
            )}
            <tr>
              <td className={style.label}>Funding Date</td>
              <td className={style.value}>
                {moment(fundedDataById?.funded_date).format("YYYY-MM-DD")}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Participant</td>
              <td className={style.value}>
                {fundedDataById?.funder ? fundedDataById?.funder : "--"}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Status</td>
              <td className={style.value}>{fundedDataById?.status}</td>
            </tr>
            <tr>
              <td className={style.label}>
                Schedule Status
                <CustomButton
                  text="Refresh"
                  type="btn-primary"
                  size="btn-sm"
                  style={{ marginLeft: "10px" }}
                  handleClick={() => {
                    handlePandaDocsStatus();
                  }}
                />
              </td>
              <td className={style.value}>
                {pandaDocStatus?.status ? pandaDocStatus?.status : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={style.fundedViewRightWrap}>
        <div className={style.iframeStyle}>
          <div className={style.documentsWrap}>
            {Object?.keys(documentsGroupItem)?.map((imagesType, index) => (
              <div key={index}>
                <h3>{imagesType}</h3>
                <div className={style.documentsButtonWrap}>
                  {documentsGroupItem?.[imagesType]?.map((doc, idx) => (
                    <button
                      className={"btn-primary btn-sm"}
                      style={{}}
                      onClick={() => {
                        handleIndexFile(idx, doc, imagesType);
                        setExcelData();
                      }}
                    >
                      {idx + 1}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {currentIndex.imagesType &&
            documentsGroupItem?.[currentIndex?.fileType]?.[
              currentIndex?.docIndex
            ]?.file && (
              <>
                {getFileType(
                  documentsGroupItem?.[currentIndex?.fileType]?.[
                    currentIndex?.docIndex
                  ]?.file,
                ) === "docx" && (
                  <FileViewer
                    fileType="docx"
                    filePath={
                      documentsGroupItem?.[currentIndex?.fileType]?.[
                        currentIndex?.docIndex
                      ]?.file
                    }
                    pluginRenderers={DocViewerRenderers}
                  />
                )}
                {(getFileType(
                  documentsGroupItem?.[currentIndex?.fileType]?.[
                    currentIndex?.docIndex
                  ]?.file,
                ) === "png" ||
                  getFileType(
                    documentsGroupItem?.[currentIndex?.fileType]?.[
                      currentIndex?.docIndex
                    ]?.file,
                  ) === "jpeg") && (
                  <img
                    src={
                      documentsGroupItem?.[currentIndex?.fileType]?.[
                        currentIndex?.docIndex
                      ]?.file
                    }
                    alt="images"
                  />
                )}

                {getFileType(
                  documentsGroupItem?.[currentIndex?.fileType]?.[
                    currentIndex?.docIndex
                  ]?.file,
                ) === "pdf" && (
                  <FileViewer
                    fileType="pdf"
                    filePath={
                      documentsGroupItem?.[currentIndex?.fileType]?.[
                        currentIndex?.docIndex
                      ]?.file
                    }
                    pluginRenderers={PDFRenderer}
                  />
                )}
                {excelData && (
                  <div className={style.excelBoadyWrap}>
                    <table border="1">
                      <thead>
                        <tr>
                          {excelData[0].map((header, i) => (
                            <th className={style.headerStyleCell} key={i}>
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData.slice(1).map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, j) => (
                              <td className={style.columnCellStyle} key={j}>
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};
export default BasicInfo;
