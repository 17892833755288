import { Formik } from "formik";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import { CurrencyData, ImagestypeData } from "../../../../../utils/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import style from "./index.module.scss";
import { Heading } from "../../../../atoms/Heading";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import * as Yup from "yup";
import MyDropdown from "../../../../atoms/MyDropdown";
import { useState } from "react";
import { Obj2FormImage } from "../../../../../helpers/capatalizeLetters";
import Close from "../../../../icons/Close";
import MyInput from "../../../../atoms/MyInput";
import moment from "moment";
import Modal from "../../../../atoms/modals/Modal";

const Client = ({ clientDetails, selectedTransactionType }) => {
  const navigate = useNavigate();
  const [cookie] = useCookies(["t"]);
  const [selectedImage, setSelectedImage] = useState([]);

  const [modalStateImage, setModalStateImage] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    data: "",
  });

  const [modalImageType, setModalImagesType] = useState({
    isModalOpen: false,
    modalTitle: "",
  });

  const [imagesType, setImagesType] = useState([]);
  const [imagesName, setImagesName] = useState();

  const handleCloseModal = () => {
    setModalStateImage(() => ({
      isModalOpen: false,
      data: "",
    }));
  };

  const handleRemoveImage = (imageToRemove) => {
    setSelectedImage((prevImages) =>
      prevImages.filter((image) => image !== imageToRemove),
    );
    setImagesType((prev) =>
      prev.filter((item) => item.fileName !== imageToRemove.name),
    );
  };
  const handleMultipleImages = (file, imagesType) => {
    setSelectedImage((prev) => [
      ...prev,
      { file: file, imagesType: imagesType },
    ]);
  };

  const handleClosrModalImagesType = () => {
    setModalImagesType(() => ({
      isModalOpen: false,
      modalTitle: "",
    }));
  };

  const initialValues = {
    due_date: "",
    tennor_days: "",
    currency: "",
    loan_amount: "",
    referance: "",
    client_supporting_docs: "",
  };
  const validationSchema = Yup.object().shape({
    due_date: Yup.string().required("Due date is required"),
    tennor_days: Yup.string().required("Tennor days is required"),
    currency: Yup.string().required("Currency is reuqired"),
    loan_amount: Yup.number().required("Loan amount is required"),
    referance: Yup.string().required("Referance is required"),
    client_supporting_docs: Yup.array().test(
      "images-length",
      "Supporting documents is required",
      function (value) {
        // Custom test to check if images length is greater than 0
        return selectedImage.length > 0;
      },
    ),
  });

  const handleSubmit = async (values) => {
    const dataToSend = {
      due_date: values?.due_date,
      tenure_days: values?.tennor_days,
      currency: values?.currency,
      cost_value: values?.loan_amount,
      referance: values?.referance,
      client_id: clientDetails?.clientId,
      client_name: clientDetails?.clientName,
      status: "Draft",
      transaction_type: selectedTransactionType.selectedType,
      documents: selectedImage,
      funded_date: moment().add(1, "days").format("YYYY-MM-DD"),
    };
    try {
      const res = await postFormData({
        endpoint: "funding/createFunding",
        token: cookie.t,
        data: dataToSend,
      });
      if (res) {
        toast.success("Funding Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      navigate("/dashboard/funding");
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        key={1234567}
        onSubmit={handleSubmit}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            <Heading className="addHeaderStyle mt-4">Funding Details</Heading>
            <div
              className="d-flex flex-wrap w-100 "
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="Due Date"
                  onChange={(option) => {
                    setFieldValue("due_date", option);
                    const todays = moment();
                    const targetDate = moment(option);

                    const diffInDays = targetDate.diff(todays, "days");
                    setFieldValue("tennor_days", diffInDays);
                  }}
                  touched={touched.due_date}
                  selectedDate={values.due_date}
                  errors={errors.due_date}
                  handleBlur={(e) => {
                    setFieldTouched("due_date", true);
                    handleBlur(e);
                  }}
                  disablePast
                />
              </div>
              <MyInput
                type="text"
                name="tennor_days"
                customholder="Tennor Days(From Today)"
                onBlur={(e) => {}}
                disabled
                InputHeight="small"
                width="23.9%"
              />
              <MyDropdown
                data={CurrencyData}
                placeholder="Currency"
                onChange={(option) => {
                  setFieldValue("currency", option);
                }}
                touched={touched.currency}
                errors={errors.currency}
                handleBlur={(e) => {
                  setFieldTouched("currency", true);
                  handleBlur(e);
                }}
                width="23.9%"
              />
              <MyInput
                type="text"
                name="loan_amount"
                customholder="Loan Amount/Revolver Face Value"
                onBlur={(e) => {}}
                InputHeight="small"
                width="23.9%"
              />
              <MyInput
                type="text"
                name="referance"
                customholder="References(s)"
                onBlur={(e) => {}}
                InputHeight="small"
                width="23.9%"
              />
              <div style={{ width: "23.9%" }}>
                <ImageSelector
                  placeholder="Upload Supporting Documents"
                  handleFile={async (file) => {
                    const formData = new FormData();
                    formData.append("files", file, file?.name);
                    const res = await postFormData({
                      endpoint: "funding/uploadFile",
                      token: cookie.t,
                      data: Obj2FormImage(formData),
                    });
                    setImagesName(res?.data?.data?.[0]);
                    setModalImagesType(() => ({
                      isModalOpen: true,
                      modalTitle: "Select File Type:",
                    }));
                  }}
                  multiple
                  touched={touched.client_supporting_docs}
                  errors={errors.client_supporting_docs}
                />
              </div>
              {selectedImage?.map((item) => {
                return (
                  <div style={{ width: "23.9%" }}>
                    <div className={style.imagesNameStyle}>
                      <span
                        className={style.imagesName}
                        onClick={() => {
                          if (
                            item?.type === "application/pdf" ||
                            item?.type === "image/jpeg"
                          ) {
                            setModalStateImage(() => ({
                              data: item?.file,
                              isModalOpen: true,
                            }));
                          } else {
                            //docs and excel not supported on local
                            setModalStateImage(() => ({
                              data: item?.file,
                              isModalOpen: true,
                            }));
                          }
                        }}
                      >
                        {
                          item?.file?.split("/")?.[
                            item?.file?.split("/")?.length - 1
                          ]
                        }
                      </span>
                      <div
                        className={style.closeIcon}
                        onClick={() => {
                          handleRemoveImage(item);
                        }}
                      >
                        <Close />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Add
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
      <Modal
        isActive={modalImageType.isModalOpen}
        title={modalImageType.modalTitle}
        onClose={handleClosrModalImagesType}
        width="400px"
        bodyStyles={{ height: "400px" }}
      >
        <MyDropdown
          data={ImagestypeData}
          onChange={(option) => {
            handleMultipleImages(imagesName, option);
            setModalImagesType(() => ({
              isModalOpen: false,
            }));
          }}
          placeholder="Select Images Type"
        />
      </Modal>
    </>
  );
};
export default Client;
