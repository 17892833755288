import { Formik } from "formik";
import MyDropdown from "../../../atoms/MyDropdown";
import CounterDropDown from "../../../molecules/CounterDataDropdown";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getData } from "../../../../services";
import { useCookies } from "react-cookie";
import TagDropdown from "../../../atoms/MyDropdown/TagDropdown";

const ProfileMonitoring = () => {
  const navigate = useNavigate();
  // const [cookies] = useCookies(["t"]);
  // const [supplierObligorData, setSupplierObligorData] = useState([]);
  // const [clientData, setClientData] = useState([]);
  // const getSupplierAndObligor = async (value) => {
  //   try {
  //     const res = await getData({
  //       endpoint: "couterParty/clientSupplierAndObligor",
  //       token: cookies.t,
  //       params: {
  //         id: value,
  //       },
  //     });
  //     const tempData = res?.data
  //       ?.filter((item) => item.tag !== "Client")
  //       .map((item) => ({
  //         label: item.label,
  //         value: item.value,
  //         tag: item.tag,
  //       }));

  //     if (tempData) setSupplierObligorData(tempData);
  //     else setSupplierObligorData([]);
  //     return tempData;
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };

  // console.log("supplierObjegorData :", supplierObligorData);

  return (
    <>
      <Formik
        // initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        // onSubmit={handleSubmit}
        // validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          isSubmitting,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          resetForm,
        }) => (
          <>
            <div
              className="d-flex flex-wrap"
              style={{
                columnGap: "1.36%",
                rowGap: "16px",
                marginTop: "20px",
                // width: "30%",
              }}
            >
              <div style={{ width: "30%" }}>
                <CounterDropDown
                  placeholder="Client Name"
                  // data={supplierObligorData}
                  onChange={async (option) => {
                    if (option && option?.value) {
                      navigate(`details/${option?.value}`);
                    }
                  }}
                  selectedValue={values?.client_id}
                />
              </div>
              {/* {supplierObligorData && supplierObligorData.length > 0 && (
                <div style={{ width: "30%" }}>
                  <TagDropdown
                    data={supplierObligorData}
                    placeholder="Supplier Name"
                    onChange={(option) => {
                      if (option && option?.value) {
                        navigate(`details/${option?.value}`);
                      }
                    }}
                    format="all"
                    menuOpen={true}
                    tagSplit={false}
                  />
                </div>
              )} */}
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default ProfileMonitoring;
