import { createSlice } from "@reduxjs/toolkit";
import { getData } from "../../services";

export const accessSlice = createSlice({
  name: "accessData",
  initialState: {
    accessData: {},
  },
  reducers: {
    setAccessData: (state, action) => {
      state.accessData = action.payload;
    },
  },
});

export const { setAccessData } = accessSlice.actions;
export const stateSelector = (state) => state?.accessSlice?.accessData;

export const fetchAccessData =
  ({ role, token }) =>
  async (dispatch) => {
    console.log("role :", role, token);
    try {
      const res = await getData({
        endpoint: "role/getRoleByID",
        params: { id: role },
        token: token,
      });

      const data = res.data.role_access;

      console.log("data:", data);
      const transformedData = data.reduce((acc, obj) => {
        const { module, ...permissions } = obj; // Extract module and permissions
        acc[module] = permissions; // Assign permissions to the module key
        return acc;
      }, {});
      dispatch(setAccessData(transformedData));
    } catch (error) {}
  };
export default accessSlice.reducer;
