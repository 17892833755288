import { useNavigate, useParams } from "react-router-dom";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import { useCookies } from "react-cookie";
import { Formik } from "formik";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { CurrencyData } from "../../../../../utils/staticData";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { useEffect, useState } from "react";
import MyDropdown from "../../../../atoms/MyDropdown";
import MyInput from "../../../../atoms/MyInput";

const AddPayment = () => {
  const navigate = useNavigate();
  const [cookie] = useCookies(["t"]);
  const { id } = useParams();
  const [editedPaymentData, setEditedPaymentData] = useState({});
  const [clientData, setClientData] = useState([]);
  const [obligorData, setObligorData] = useState([]);

  const initialValues = {
    amount: editedPaymentData?.amount || "",
    currency: editedPaymentData?.currency || "",
    client_id: editedPaymentData?.client_id || "",
    client_name: editedPaymentData?.client_name || "",
    obligor_id: editedPaymentData?.obligor_id || "",
    obligor_name: editedPaymentData?.obligor_name || "",
    date: editedPaymentData?.date || "",
    status: "Review",
    transaction_type: "",
  };
  const getPaymentDataById = async () => {
    try {
      const res = await getData({
        endpoint: "payment/getPaymentById",
        token: cookie.t,
        params: {
          id: id,
        },
      });
      setEditedPaymentData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getPaymentDataById();
    }
  }, [id]);

  const handleSubmit = async (values) => {
    const dataToSend = {
      client_id: values?.client_id || "",
      client_name: values?.client_name || "",
      obligor_id: values?.obligor_id || "",
      obligor_name: values?.obligor_name || "",
      amount: values?.amount || "",
      date: values?.date || "",
      currency: values?.currency || "",
      status: "New",
      transaction_type: values?.transaction_type?.length
        ? values?.transaction_type
        : "Client",
    };
    if (id) {
      try {
        const res = await patchData({
          endpoint: "payment/updateStatus",
          token: cookie.t,
          params: {
            ids: id,
            status: "Review",
          },
          data: dataToSend,
        });
        if (res) {
          toast.success("Payment Details Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/payments");
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      try {
        const res = await postData({
          endpoint: "payment/create",
          token: cookie.t,
          data: dataToSend,
        });
        if (res) {
          toast.success("Payment Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/payments");
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const getClientData = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookie.t,
        params: {
          conterparty_type: "Client",
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getClientData();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        // key={Math.random()}
        onSubmit={handleSubmit}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb labels={["Payment", "Add Payment"]} />
            <Heading className="addHeaderStyle">Add Payment</Heading>
            <div
              className="d-flex flex-wrap w-100 "
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyInput
                type="text"
                name="amount"
                customholder="Amount"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
              <MyDropdown
                data={CurrencyData}
                placeholder="Payment Currency"
                onChange={(option) => {
                  setFieldValue("currency", option);
                }}
                selectedValue={values?.currency}
                width="23.9%"
              />
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="Payment Date"
                  onChange={async (option) => {
                    setFieldValue("date", option);
                  }}
                  selectedDate={values.date}
                  width="23.9%"
                  disablePast
                />
              </div>
              <MyDropdown
                placeholder="Payment Client"
                data={clientData}
                onChange={async (option) => {
                  setFieldValue("client_id", option?.value);
                  setFieldValue("client_name", option?.label);
                  const res = await getData({
                    endpoint: "couterParty/clientSupplierAndObligor",
                    token: cookie.t,
                    params: {
                      conterparty_type: JSON.stringify(["Obligor", "Supplier"]),
                      id: option?.value,
                    },
                  });
                  setObligorData(res?.data);
                }}
                selectedValue={values?.client_id}
                format="all"
                width="23.9%"
              />
              <MyDropdown
                placeholder="Payment Obligor"
                data={obligorData}
                onChange={(option) => {
                  setFieldValue("obligor_id", option?.value);
                  setFieldValue("obligor_name", option?.label);
                  setFieldValue("transaction_type", option?.tag);
                }}
                format="all"
                selectedValue={values?.obligor}
                width="23.9%"
              />
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                />
                <CustomButton
                  text={id ? "Update" : "Add"}
                  type="btn-primary"
                  disabled={!isValid || !dirty || isSubmitting}
                  size="btn-lg"
                  handleClick={() => handleSubmit(values)}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default AddPayment;
