import React, { useEffect, useState } from "react";
import { FieldArray, Formik, Field } from "formik";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import { Heading } from "../../../../../atoms/Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import MyDropdown from "../../../../../atoms/MyDropdown";
import TextInput from "../../../../../atoms/TextInput";
import MyTextArea from "../../../../../atoms/MyTextArea";
import CustomButton from "../../../../../atoms/CustomButton";
import * as Yup from "yup";
import CounterDropDown from "../../../../../molecules/CounterDataDropdown";
import {
  convenantFrequency,
  ReportingNames,
} from "../../../../../../helpers/staticData";
import { postData, patchData, getData } from "../../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/components/toast";
import {
  monthly_shift,
  reportingDays,
} from "../../../../../../helpers/staticData";

const AddCovenant = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [initialData, setInitialData] = useState({});
  const [clientName, setClientName] = useState({ value: "", label: "" });

  useEffect(() => {
    if (type === "edit") {
      (async () => {
        const res = await getData({
          endpoint: "/reporting-requirements/getReportingDetailById",
          params: { id: id },
          token: cookies.t,
        });
        setInitialData(res.data);
        setClientName({
          value: res.data.client_id,
          label: res.data.client_name,
        });
      })();
    }
  }, [type]);

  const initialValues = {
    client_id: initialData?.client_id || "",
    reporting_requirement_details: initialData?.reporting_requirement_details || [
      {
        name: initialData?.name || "",
        long_description: initialData?.long_description || "",
        frequency: initialData?.frequency || "",
        reporting_days: initialData?.reporting_days || "",
        monthly_shift: initialData?.monthly_shift || "",
        other_notes: initialData?.other_notes || "",
        next_reporting_date: initialData?.next_reporting_date || null,
        next_due_date: initialData?.next_due_date || null,
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    reporting_requirement_details: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        long_description: Yup.string().required("Description is required"),
        frequency: Yup.string().required("Frequency is required"),
        reporting_days: Yup.string().required("Reporting Days are required"),
        monthly_shift: Yup.string().required("Monthly Shift is required"),
      }),
    ),
    client_id: Yup.string().required("Client Name is required"),
  });

  const handleSubmit = async (values) => {
    const submitData = values

    if (id) {
      const res = await patchData({
        endpoint: "reporting-requirements/update",
        token: cookies.t,
        data: submitData,
        params: { id: id },
      });
      if (res) {
        toast.success("Reporting Requirement Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/setting/reporting-requirements");
      }
    } else {
      try {
        const res = await postData({
          endpoint: "reporting-requirements/create",
          data: submitData,
          token: cookies.t,
        });
        if (res) {
          toast.success("Reporting Requirement Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/setting/reporting-requirements");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random()}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          resetForm,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={
                type === "edit"
                  ? ["Reporting Requirements", "Edit Reporting Requirement"]
                  : ["Reporting Requirements", "Add Reporting Requirement"]
              }
              excludeLastPath={type === "edit"}
            />
            <Heading className="addHeaderStyle">
              {id ? "Edit" : "Add"} Reporting Requirement
            </Heading>

            <div className="row g-3 mt-3">
              {/* Client Name Field (Separate) */}
              <div className="col-3 mt-3">
                <CounterDropDown
                  placeholder="Client Name"
                  onChange={(option) => {
                    setFieldValue("client_name", option.label);
                    setFieldValue("client_id", option?.value);
                  }}
                  selectedValue={clientName.value}
                  format="all"
                  isError={errors.client_id}
                  touched={touched.client_id}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("client_id", true);
                  }}
                  errors={errors.client_id}
                />
              </div>

              {/* Fields as an Array */}
              <FieldArray
                name="reporting_requirement_details"
                render={(arrayHelpers) => (
                  <>
                    {values.reporting_requirement_details.length > 0 &&
                      values.reporting_requirement_details.map(
                        (field, index) => (
                          <div key={index}>
                            <div className="d-flex justify-content-between mt-4">
                              <h5 className="fw-semi mb-0">
                                Reporting Requirement {index + 1}
                              </h5>

                              <div className="d-flex gap-3">
                                {values.reporting_requirement_details
                                  ?.length ===
                                  index + 1 && (
                                  <button
                                    className="btn btn-primary px-4"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        name: "",
                                        long_description: "",
                                        frequency: "",
                                        reporting_days: "",
                                        monthly_shift: "",
                                        other_notes: "",
                                        next_reporting_date: null,
                                        next_due_date: null,
                                      })
                                    }
                                  >
                                    Add More
                                  </button>
                                )}

                                {values.reporting_requirement_details
                                  ?.length !== 1 && (
                                  <button
                                    onClick={() => arrayHelpers.remove(index)} // Remove the current field set
                                    className="btn btn-outline-dark px-4 "
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="row g-3 mt-3">
                              <div className="col-3 mt-3">
                                <MyDropdown
                                  placeholder="Name"
                                  data={ReportingNames}
                                  onChange={(option) =>
                                    setFieldValue(
                                      `reporting_requirement_details[${index}].name`,
                                      option,
                                    )
                                  }
                                  selectedValue={field.name}
                                  isError={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.name
                                  }
                                  touched={
                                    touched?.reporting_requirement_details?.[
                                      index
                                    ]?.name
                                  }
                                  handleBlur={handleBlur}
                                  errors={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.name
                                  }
                                  info={"Short Description of Reporting"}
                                />
                              </div>

                              <div className="col-6 mt-3">
                                <MyTextArea
                                  as="textarea"
                                  customholder="Explain"
                                  name={`reporting_requirement_details[${index}].long_description`}
                         
                                  info="Long Description of Reporting"
                                  isError={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.long_description
                                  }
                                  touched={
                                    touched?.reporting_requirement_details?.[
                                      index
                                    ]?.long_description
                                  }
                                  width="w-100"
                                  style={{ minHeight: "46px" }}
                                />
                              </div>
                            </div>

                            <div className="row g-3 mt-3">
                              <div className="col-3 mt-3">
                                <MyDropdown
                                  placeholder="Frequency"
                                  data={convenantFrequency}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `reporting_requirement_details[${index}].frequency`,
                                      option.label,
                                    );
                                  }}
                                  selectedValue={field?.frequency}
                                  format="all"
                                  isError={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.frequency
                                  }
                                  touched={
                                    touched?.reporting_requirement_details?.[
                                      index
                                    ]?.frequency
                                  }
                                  handleBlur={(e) => {
                                    handleBlur(e);
                                    setFieldTouched(
                                      `reporting_requirement_details[${index}].frequency`,
                                      true,
                                    );
                                  }}
                                  errors={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.frequency
                                  }
                                />
                              </div>
                              <div className="col-3 mt-3">
                                <MyDropdown
                                  placeholder="Monthly Shift"
                                  data={monthly_shift}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `reporting_requirement_details[${index}].monthly_shift`,
                                      option,
                                    );
                                  }}
                                  selectedValue={field.monthly_shift} // Use field.monthly_shift for the selected value
                                  isError={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.monthly_shift
                                  }
                                  touched={
                                    touched?.reporting_requirement_details?.[
                                      index
                                    ]?.monthly_shift
                                  }
                                  handleBlur={(e) => {
                                    handleBlur(e);
                                    setFieldTouched(
                                      `reporting_requirement_details[${index}].monthly_shift`,
                                      true,
                                    );
                                  }}
                                  errors={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.monthly_shift
                                  }
                                  info="For quarterly max shift is 2, for annual Max shift is 11"
                                />
                              </div>

                              {console.log("vals", values)}
                              <div className="col-3 mt-3">
                                {" "}
                                <MyDropdown
                                  placeholder="Reporting Days"
                                  data={reportingDays}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `reporting_requirement_details[${index}].reporting_days`,
                                      option,
                                    );
                                    setFieldValue(
                                      `reporting_requirement_details[${index}].next_reporting_date`,
                                      new Date().toISOString(),
                                    );

                                    const nextDueDate = new Date();
                                    nextDueDate.setDate(
                                      nextDueDate.getDate() + option,
                                    );
                                    setFieldValue(
                                      `reporting_requirement_details[${index}].next_due_date`,
                                      nextDueDate.toISOString(),
                                    );
                                  }}
                                  selectedValue={field.reporting_days} // Use field.reporting_days for the selected value
                                  isError={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.reporting_days
                                  }
                                  touched={
                                    touched?.reporting_requirement_details?.[
                                      index
                                    ]?.reporting_days
                                  }
                                  handleBlur={(e) => {
                                    handleBlur(e);
                                    setFieldTouched(
                                      `reporting_requirement_details[${index}].reporting_days`,
                                      true,
                                    );
                                  }}
                                  errors={
                                    errors?.reporting_requirement_details?.[
                                      index
                                    ]?.reporting_days
                                  }
                                  info="If reporting days are N/A. Please select 0."
                                />
                              </div>
                              <div className="col-3 mt-3">
                                <MyTextArea
                                  as="textarea"
                                  customholder="Other Notes"
                                  name={`reporting_requirement_details[${index}].other_notes`} // Corrected name prop for the textarea
                                  width="w-100"
                                />
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                  </>
                )}
              />
            </div>

            <div className="buttonWrap mt-5">
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => navigate(-1)}
                />
              </div>
              <div className="resetAdd">
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting}
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  {id ? "Update" : "Submit"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddCovenant;
