import { useCookies } from "react-cookie";
import { getData, patchData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import style from "./index.module.scss";
import { memo, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { Box } from "@mui/system";
import TabStrip from "../../../../../atoms/tabstrip";
import { Heading } from "../../../../../atoms/Heading";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../../atoms/NoContentCard";
import ReactEcharts from "echarts-for-react";
import MyDropdown from "../../../../../atoms/MyDropdown";

const ApprovedModal = ({
  modalStateApproved,
  setModalStateApproved,
  getAllFundingStatus,
}) => {
  const [cookies] = useCookies(["t"]);
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fundingDueData, setFundingDueData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState();

  const [activeTab, setActiveTab] = useState("Credit info");

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const getInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorList",
        token: cookies.t,
      });
      setInvestorData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getInvestorData();
  }, []);

  const data = [
    {
      id: 1,
      label: "Credit info",
      view: <CreditInfo fundingData={fundingData} clientData={clientData} />,
    },
    {
      id: 2,
      label: "Exiting Allocation ",
      view: <ExitingAllocation fundingDueData={fundingDueData} />,
    },
    {
      id: 3,
      label: "Allocation History",
      view: <AllocationHistory fundingDueData={fundingDueData} />,
    },
  ];

  const getFundingById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: modalStateApproved.modalId,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getClientById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: fundingData?.client_id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (fundingData?.client_id) {
      getClientById();
    }
  }, [fundingData?.client_id]);

  useEffect(() => {
    if (modalStateApproved?.modalId) {
      getFundingById();
    }
  }, [modalStateApproved?.modalId]);

  const getFundingDues = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingByClientId",
        params: {
          id:
            fundingData?.transaction_type === "Supplier" ||
            fundingData?.transaction_type === "Supplier Credit-1"
              ? fundingData?.supplier_id
              : fundingData?.transaction_type === "Obligor"
              ? fundingData?.obligor_id
              : fundingData?.client_id,
          status: "Funded",
          trans_type: fundingData?.transaction_type,
        },
        token: cookies.t,
      });
      setFundingDueData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (fundingData?.client_id) {
      getFundingDues();
    }
  }, [fundingData]);

  const handleApproveFunding = async () => {
    try {
      setIsLoading(true);
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateApproved.modalId,
        },
        data: {
          status: "Allocated",
          investor_id: selectedInvestor?.value,
          funder: selectedInvestor.investorTag,
        },
      });
      if (res) {
        toast.success("Funding Submitted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      setIsLoading(false);
      setModalStateApproved(() => ({
        isModalOpen: false,
      }));
      getAllFundingStatus();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>
      <div className="row">
        <label className={style.selectInvestor}>Select Investor</label>
        <div className="col-6">
          <div className="mt-4 mb-4">
            <MyDropdown
              data={investorData}
              placeholder="Please select Investors"
              onChange={(option) => {
                setSelectedInvestor(option);
              }}
              format="all"
            />
          </div>
        </div>
      </div>
      <div className={style.buttonStyle}>
        {/* <CustomButton
          text="Confirm Allocation"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            handleApproveFunding();
          }}
        /> */}
        <button
          className="btn-primary btn-lg"
          type="submit"
          style={{ width: "25%" }}
          onClick={() => {
            handleApproveFunding();
          }}
        >
          Confirm Allocation
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm ms-2 text-light"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <CustomButton
          text="Cancel"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setModalStateApproved(() => ({
              isModalOpen: false,
            }));
          }}
        />
      </div>
    </>
  );
};
export default ApprovedModal;

const CreditInfo = ({ fundingData, clientData }) => {
  return (
    <>
      <div>
        <div className="row">
          <label className={style.terms}>Asset Info</label>
          <div className="col-6">
            <div className={style.value}>Cost</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.cost_value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Face Value</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {parseFloat(fundingData.face_value) +
                parseFloat(fundingData?.cost_value)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Currency</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.currency}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Schedule status</div>
          </div>
          <div className="col-6">
            <div className={style.value}>document complete</div>
          </div>
        </div>
        <div className="row">
          <label className={style.terms}>Obligor Information</label>
          <div className="col-6">
            <div className={style.value}>Name</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.counter_name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Internal Credit Limit</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.credit_limit}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Credit Used</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.cost_value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Credit Remaining</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {parseFloat(clientData?.credit_limit) -
                parseFloat(fundingData?.cost_value)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ExitingAllocation = ({ fundingDueData }) => {
  const groupAndSumCostValue = (data) => {
    const grouped = data.reduce((acc, current) => {
      const key = `${current.funder}-${current.currency}`;
      if (!acc[key]) {
        acc[key] = {
          funder: current.funder,
          currency: current.currency,
          totalCostValue: 0,
        };
      }
      acc[key].totalCostValue += parseFloat(current.cost_value);

      return acc;
    }, {});
    return Object.values(grouped);
  };

  const groupedData = groupAndSumCostValue(fundingDueData);

  const options = {
    title: {
      text: "Exiting Alloction",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "5%",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "50%",
        data: groupedData?.map((item) => {
          return {
            name: item?.funder + "-" + item?.currency,
            value: item?.totalCostValue,
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <>
      <ReactEcharts option={options} style={{ height: "408px" }}></ReactEcharts>
    </>
  );
};
const AllocationHistory = ({ fundingDueData }) => {
  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"No Content"}
          subtitle={"No Content"}
          type="Allocation History"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  const columns = [
    {
      field: "client",
      headerName: "Client",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.client_name}</div>;
      },
    },
    {
      field: "obligor",
      headerName: "Obligor",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.obligor_name}</div>;
      },
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.supplier_name}</div>;
      },
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.funder}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{parseFloat(params?.row?.cost_value)}</div>;
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div>
            {parseFloat(params?.row?.face_value) +
              parseFloat(params?.row?.cost_value)}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.transaction_type}</div>;
      },
    },
  ];
  return (
    <>
      <CustomDatagrid
        tHeight={"25.25rem"}
        rows={fundingDueData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
    </>
  );
};
