import FileViewer from "react-file-viewer";
import CustomButton from "../../../../../atoms/CustomButton";
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import style from "../index.module.scss";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import moment from "moment";

const SummaryInterest = ({ pricingData, fundedDataById }) => {
  const [cookies] = useCookies(["t"]);
  const [fundingData, setFundingData] = useState();
  const [excelData, setExcelData] = useState(null);
  const [excelTypeData, setExcelTypeData] = useState({
    fileType: "",
    file: "",
  });
  const [currentIndex, setCurrentIndex] = useState({
    docIndex: 0,
    fileType: "",
    imagesType: "", // Add imagesType to the state
  });
  const [paymentDetails, setPaymentDetails] = useState();
  const [documentsGroupItem, setDocumentsGroupItem] = useState({});
  console.log("fundingData", fundingData);

  const getPaymentDetails = async () => {
    try {
      const res = await getData({
        endpoint: "payment/getPaymentById",
        token: cookies.t,
        params: {
          id: fundingData?.paymentHistory?.[0]?.payment_id,
        },
      });
      setPaymentDetails([res?.data]);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (fundingData?.paymentHistory) {
      getPaymentDetails();
    }
  }, [fundingData?.paymentHistory]);

  console.log("paymentDetails", paymentDetails);

  useEffect(() => {
    if (fundedDataById?.documents) {
      setFundingData(fundedDataById);
    }
    if (fundedDataById?.documents) {
      const groupedDocuments = fundedDataById?.documents.reduce((acc, doc) => {
        if (!acc[doc?.imagesType]) {
          acc[doc?.imagesType] = [];
        }
        acc[doc?.imagesType].push(doc);
        return acc;
      }, {});
      setDocumentsGroupItem(groupedDocuments);
      const firstImagesType = Object.keys(groupedDocuments)[0];
      if (firstImagesType) {
        setCurrentIndex({
          docIndex: 0, // Set to the first document
          fileType: groupedDocuments[firstImagesType][0]?.imagesType,
          imagesType: firstImagesType, // Set to the first imagesType
        });
      }
    }
  }, [fundedDataById]);

  const handleIndexFile = (index, doc, imagesType) => {
    setCurrentIndex(() => ({
      docIndex: index,
      fileType: doc?.imagesType,
      imagesType: imagesType, // Set the imagesType when a file is clicked
    }));

    const type = getFileType(doc?.file);
    if (type.toLowerCase() === "xls" || type.toLowerCase() === "xlsx") {
      setExcelTypeData(() => ({
        fileType: type,
        file: doc?.file,
      }));
    }
  };
  const xlsxFileDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      setExcelData(sheetData);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (excelTypeData?.file) {
      xlsxFileDownload(excelTypeData.file);
    }
  }, [excelTypeData]);

  useEffect(() => {
    if (fundingData?.documents?.[0]) {
      const fileTypeAtIndex0 = getFileType(fundingData.documents[0]?.file);
      if (fileTypeAtIndex0 === "xls" || fileTypeAtIndex0 === "xlsx") {
        setExcelTypeData(() => ({
          fileType: fileTypeAtIndex0,
          file: fundingData.documents[0]?.file,
        }));
      }
    }
  }, [fundingData?.documents, currentIndex]); // Run this effect when fundingData changes

  const getFileType = (url) => {
    const extension = url?.split(".")?.pop();
    return extension?.toLowerCase();
  };

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <div>
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray">
                <th colSpan="4" className="border p-2 text-left">
                  Payment
                </th>
              </tr>
              <tr className="bg-gray">
                <th className="border p-2 text-left">Payment Amount</th>
                <th className="border p-2 text-left">Currency</th>
                <th className="border p-2 text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails?.map((item) => {
                return (
                  <tr>
                    <td className="border p-2">{item?.amount}</td>
                    <td className="border p-2">{item?.currency}</td>
                    <td className="border p-2">
                      {moment(item?.date).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <Heading className="HeadingSubStyle">Accrued Date</Heading>
          <label>As of Date</label>
          <div style={{ position: "relative", width: "100%" }}>
            <DateSelector
              placeholder="Select Date"
              onChange={(option) => {
                console.log("option", option);
              }}
            />
          </div>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Pricing Information</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Name</td>
                <td className={style.value}>{pricingData?.name}</td>
              </tr>
              <tr>
                <td className={style.label}>Description</td>
                <td className={style.value}>{pricingData?.description}</td>
              </tr>
              <tr>
                <td className={style.label}>Top Rate</td>
                <td className={style.value}>{pricingData?.top_rate}</td>
              </tr>
              <tr>
                <td className={style.label}>Days</td>
                <td className={style.value}>{pricingData?.days}</td>
              </tr>
              <tr>
                <td className={style.label}>Advance Rate</td>
                <td className={style.value}>{pricingData?.advance_rate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.fundedViewRightWrap}>
        <div className={style.iframeStyle}>
          <div className={style.documentsWrap}>
            {Object?.keys(documentsGroupItem)?.map((imagesType, index) => (
              <div key={index}>
                <h3>{imagesType}</h3>
                <div className={style.documentsButtonWrap}>
                  {documentsGroupItem?.[imagesType]?.map((doc, idx) => (
                    <button
                      className={"btn-primary btn-sm"}
                      style={{}}
                      onClick={() => {
                        handleIndexFile(idx, doc, imagesType);
                        setExcelData();
                      }}
                    >
                      {idx + 1}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {currentIndex.imagesType &&
            documentsGroupItem?.[currentIndex?.fileType]?.[
              currentIndex?.docIndex
            ]?.file && (
              <>
                {(getFileType(
                  documentsGroupItem?.[currentIndex?.fileType]?.[
                    currentIndex?.docIndex
                  ]?.file,
                ) === "png" ||
                  getFileType(
                    documentsGroupItem?.[currentIndex?.fileType]?.[
                      currentIndex?.docIndex
                    ]?.file,
                  ) === "jpeg") && (
                  <img
                    src={
                      documentsGroupItem?.[currentIndex?.fileType]?.[
                        currentIndex?.docIndex
                      ]?.file
                    }
                    alt="images"
                  />
                )}
                {getFileType(
                  documentsGroupItem?.[currentIndex?.fileType]?.[
                    currentIndex?.docIndex
                  ]?.file,
                ) === "docx" && (
                  <FileViewer
                    fileType="docx"
                    filePath={
                      documentsGroupItem?.[currentIndex?.fileType]?.[
                        currentIndex?.docIndex
                      ]?.file
                    }
                    pluginRenderers={DocViewerRenderers}
                  />
                )}
                {getFileType(
                  documentsGroupItem?.[currentIndex?.fileType]?.[
                    currentIndex?.docIndex
                  ]?.file,
                ) === "pdf" && (
                  <FileViewer
                    fileType="pdf"
                    filePath={
                      documentsGroupItem?.[currentIndex?.fileType]?.[
                        currentIndex?.docIndex
                      ]?.file
                    }
                    pluginRenderers={PDFRenderer}
                  />
                )}
                {excelData && (
                  <div className={style.excelBoadyWrap}>
                    <table border="1">
                      <thead>
                        <tr>
                          {excelData[0].map((header, i) => (
                            <th className={style.headerStyleCell} key={i}>
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData.slice(1).map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, j) => (
                              <td className={style.columnCellStyle} key={j}>
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};
export default SummaryInterest;
